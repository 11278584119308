import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch, VModel } from 'vue-property-decorator';
import ConsentsField from '@App/components/ConsentsField.vue';
import { validateConsents } from '@/ConsentValidator';
import CheckboxInputField from '@/components/CheckboxInputField.vue';
import FieldMessages from '@Framework/components/form/FieldMessages.vue';
import { unwrap } from '@/directives/unwrap';
let FormConsents = class FormConsents extends Vue {
    constructor() {
        super(...arguments);
        this.validationState = {
            dirty: false,
        };
        this.consentsValidationResult = {};
        this.fieldMessages = {};
    }
    validate() {
        const validationResult = validateConsents(this.consents, this.consentsData);
        this.validationState.dirty = true;
        this.consentsValidationResult = validationResult.resultObject;
        this.setValidationMessages();
        return validationResult.correct;
    }
    setValidationMessages() {
        for (const [key, value] of Object.entries(this.consentsValidationResult)) {
            if (!value) {
                this.$set(this.fieldMessages, key, [this.errorMessage]);
            }
            else {
                this.$set(this.fieldMessages, key, []);
            }
        }
    }
    onConsentsUpdated(consents) {
        if (consents) {
            this.consents.forEach((consent) => {
                this.$set(this.consentsData, consent.consentID, false);
                this.$set(this.consentsValidationResult, consent.consentID, true);
                this.$set(this.fieldMessages, consent.consentID, []);
            });
        }
    }
    consentsDataChanged() {
        if (this.validationState.dirty) {
            this.validate();
        }
    }
};
__decorate([
    Prop()
], FormConsents.prototype, "consents", void 0);
__decorate([
    Prop()
], FormConsents.prototype, "errorMessage", void 0);
__decorate([
    VModel()
], FormConsents.prototype, "consentsData", void 0);
__decorate([
    Watch('consents', { immediate: true })
], FormConsents.prototype, "onConsentsUpdated", null);
__decorate([
    Watch('consentsData', { immediate: true, deep: true })
], FormConsents.prototype, "consentsDataChanged", null);
FormConsents = __decorate([
    Component({
        components: { ConsentsField, CheckboxInputField, FieldMessages },
        directives: { unwrap }
    })
], FormConsents);
export default FormConsents;
