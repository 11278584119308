//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'InputSearch',
  props: ['value', 'suggestionList', 'marginBottom', 'error'],
  components: {  },
  data() {
    return {
      isOpenSuggestion: false,
    };
  },
  computed: {
    listeners() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { input, ...listeners } = this.$listeners;

      return listeners;
    },
    attrs() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { rows, ...attrs } = this.$attrs;

      return attrs;
    },
    canClear() {
      return this.value;
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.$emit('input', suggestion);
      this.closeSuggestions();
    },
    selectInput() {
      this.isOpenSuggestion = this.suggestionList.length > 0;
    },
    openSuggestions() {
      this.isOpenSuggestion = true;
    },
    closeSuggestions() {
      this.isOpenSuggestion = false;
    },
    clear() {
      this.$emit('input', '');
      this.$emit('clear');
    },
  },
};

