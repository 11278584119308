import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
let SelectField = class SelectField extends Vue {
    get _items() {
        return this.items.map((item) => {
            if (typeof item === 'object') {
                if (!item.code || !item.label) {
                    console.warn('Invalid options for select');
                }
                return item;
            }
            else if (typeof item === 'string') {
                return {
                    code: item,
                    label: item,
                };
            }
            return null;
        }).filter((item) => item !== null);
    }
};
__decorate([
    Prop()
], SelectField.prototype, "id", void 0);
__decorate([
    Prop()
], SelectField.prototype, "props", void 0);
__decorate([
    Prop()
], SelectField.prototype, "options", void 0);
__decorate([
    VModel()
], SelectField.prototype, "model", void 0);
__decorate([
    Prop({ type: Array, default: () => ([]) })
], SelectField.prototype, "items", void 0);
SelectField = __decorate([
    Component({})
], SelectField);
export default SelectField;
