import { requestManager } from '@/services/requestManager';
import apiService from '@/services/apiService';
import ConsentsProvider from '@/ConsentsProvider';
import ResponseStatusCodeManager from '@rawlplug/response-manager';
export default () => ([
    ['service.requestManager', function () { return requestManager; }, {}],
    ['service.apiService', function () { return apiService; }, {}],
    ['provider.consents', ConsentsProvider, { args: ['hr-employee-ideas', 'pl'] }],
    ['service.responseManager', ResponseStatusCodeManager, {}]
]);
