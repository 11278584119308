import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
let PasswordInputField = class PasswordInputField extends Vue {
};
__decorate([
    Prop()
], PasswordInputField.prototype, "id", void 0);
__decorate([
    Prop()
], PasswordInputField.prototype, "props", void 0);
__decorate([
    VModel()
], PasswordInputField.prototype, "model", void 0);
PasswordInputField = __decorate([
    Component({})
], PasswordInputField);
export default PasswordInputField;
