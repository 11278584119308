import { __decorate } from "tslib";
import { Vue, Prop, Component, VModel, Watch } from 'vue-property-decorator';
import FormGroup from '@Framework/components/form/FormGroup.vue';
import { validateForm, validationMessagesMap, validateFormField } from '@Framework/components/form/FormValidator';
import * as validators from './validators';
import { getMessagesForFields } from '@Framework/components/form/FormMessager';
let FormBuilder = class FormBuilder extends Vue {
    constructor() {
        super(...arguments);
        this.validationState = {
            dirty: false,
        };
    }
    getRowData(key) {
        return this.fields.find(item => item.key === key) || null;
    }
    validate() {
        const { correct, fieldsResults } = validateForm(this.fields, this.formData, this.validators);
        this.validationState.dirty = true;
        const fieldsMessages = getMessagesForFields(fieldsResults, this.validationMessages);
        this.setMessagesToFields(fieldsMessages);
        this.setValidationResultsToFields(fieldsResults);
        return correct;
    }
    validateField(fieldName) {
        const field = this.getRowData(fieldName);
        if (field) {
            return validateFormField(field.validations, this.validators, this.formData[fieldName]);
        }
        return false;
    }
    setMessagesToFields(fieldsMessages) {
        for (const [fieldKey, messages] of Object.entries(fieldsMessages)) {
            const ref = this.$refs['formGroup-' + fieldKey];
            const formGroupComponent = Array.isArray(ref) ? ref[0] : ref;
            formGroupComponent.setFieldMessages(messages);
        }
    }
    setValidationResultsToFields(fieldsValidationResults) {
        for (const [fieldKey, fieldValidationResult] of Object.entries(fieldsValidationResults)) {
            const ref = this.$refs['formGroup-' + fieldKey];
            const formGroupComponent = Array.isArray(ref) ? ref[0] : ref;
            formGroupComponent.hasErrors = !fieldValidationResult.correct;
        }
    }
    onInput(val, key) {
        if (this.onValuesChanges?.[key]) {
            this.onValuesChanges[key](val, key, (value) => {
                this.formData[key] = value;
            });
        }
        else {
            this.formData[key] = val;
        }
    }
    fieldDataChange() {
        if (this.validationState.dirty) {
            this.validate();
        }
    }
};
__decorate([
    VModel({ type: Object, default: () => ({}) })
], FormBuilder.prototype, "formData", void 0);
__decorate([
    Prop({ type: Array, default: () => ([]) })
], FormBuilder.prototype, "fields", void 0);
__decorate([
    Prop({ type: Object, default: () => ({ ...validators }) })
], FormBuilder.prototype, "validators", void 0);
__decorate([
    Prop({ type: Object, default: () => ({ ...validationMessagesMap }) })
], FormBuilder.prototype, "validationMessages", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}) })
], FormBuilder.prototype, "onValuesChanges", void 0);
__decorate([
    Watch('formData', { immediate: true, deep: true })
], FormBuilder.prototype, "fieldDataChange", null);
FormBuilder = __decorate([
    Component({
        components: { FormGroup }
    })
], FormBuilder);
export default FormBuilder;
