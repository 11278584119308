import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import getNestedData from '@Framework/helpers/getNestedData';
import BaseLabel from '@Framework/components/form/BaseLabel.vue';
import { getFormFieldComponent } from '@Framework/components/form/FormFieldsMap';
import FieldMessages from './FieldMessages.vue';
let FormGroup = class FormGroup extends Vue {
    constructor() {
        super(...arguments);
        this.errors = false;
        this.fieldMessages = [];
    }
    getData(property, def) {
        return getNestedData(property, def, this.fieldData);
    }
    getFormFieldComponent() {
        return getFormFieldComponent(this.getData('tag', 'input'), this.getData('type', 'text'));
    }
    getFormFieldOptions() {
        return this.getFormFieldComponent()?.options || {};
    }
    setFieldMessages(messages) {
        this.fieldMessages = messages;
    }
    set hasErrors(value) {
        this.errors = value;
    }
    get hasErrors() {
        return this.errors;
    }
    get classes() {
        return this.hasErrors ? 'has-errors' : '';
    }
};
__decorate([
    Prop()
], FormGroup.prototype, "fieldData", void 0);
__decorate([
    Prop()
], FormGroup.prototype, "uid", void 0);
__decorate([
    VModel()
], FormGroup.prototype, "model", void 0);
FormGroup = __decorate([
    Component({
        components: { BaseLabel, FieldMessages }
    })
], FormGroup);
export default FormGroup;
