import VueRouter from 'vue-router';
import ioc from '@Framework/ioc';
const defaultRouterOptions = {
    mode: 'history',
    base: process.env.BASE_URL,
};
export default function installRouter(Vue, options = {}) {
    Vue.use(VueRouter);
    const routes = ioc.get('config.router').routes;
    return new VueRouter({
        ...defaultRouterOptions,
        ...options,
        routes
    });
}
