import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import IconFile from '@Framework/assets/icons/IconFile.svg';
import BinIcon from '@Framework/assets/icons/BinIcon.svg';
let DropzoneField = class DropzoneField extends Vue {
    handleDrop(e) {
        let dt = e.dataTransfer;
        this.handleFiles(dt.files);
    }
    handleFiles(files) {
        this.$emit('input', [...this.value, ...files]);
    }
    convertBytesToMB(bytes) {
        return (bytes / 1024 / 1024).toFixed(2);
    }
    deleteFile(file) {
        this.$emit('input', this.value.filter((f) => f !== file));
    }
    get filesSize() {
        return this.value.reduce((acc, cur) => {
            return acc + cur.size;
        }, 0);
    }
    get maxReached() {
        return this.filesSize > this.maxSize;
    }
    mounted() {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.$el.addEventListener(eventName, (e) => {
                e.preventDefault();
                e.stopPropagation();
            }, false);
        });
        this.$el.addEventListener('drop', this.handleDrop, false);
    }
};
__decorate([
    Prop()
], DropzoneField.prototype, "id", void 0);
__decorate([
    Prop()
], DropzoneField.prototype, "props", void 0);
__decorate([
    Prop({ default: true })
], DropzoneField.prototype, "multiple", void 0);
__decorate([
    Prop({ default: 20971520 })
], DropzoneField.prototype, "maxSize", void 0);
__decorate([
    Prop({ default: 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps' })
], DropzoneField.prototype, "accept", void 0);
__decorate([
    Prop()
], DropzoneField.prototype, "value", void 0);
DropzoneField = __decorate([
    Component({
        components: {
            IconFile, BinIcon
        }
    })
], DropzoneField);
export default DropzoneField;
