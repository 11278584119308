export default class ConsentsProvider {
    constructor(application, language) {
        this.application = application;
        this.language = language;
        this.api = "https://id.rawlplug.com/api/application";
    }
    async fetchConsents() {
        const url = this.prepareURL(this.application, this.language);
        const response = await fetch(url);
        const rawConsents = await response.json();
        const consents = this.mapConsents(rawConsents);
        return consents;
    }
    prepareURL(application, language) {
        return `${this.api}/${application}/${language}`;
    }
    prepareFetchOptions() {
        return {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
    }
    mapConsents(rawConsents) {
        return rawConsents.map((rawConsent) => {
            return {
                ID: rawConsent.id,
                consentID: rawConsent.consent_id,
                code: rawConsent.code,
                name: rawConsent.name,
                isRequired: Boolean(parseInt(rawConsent.is_required, 10)),
                description: rawConsent.description,
                categoryID: rawConsent.category_id,
                language: rawConsent.lang,
                version: rawConsent.version,
                tags: rawConsent.tags !== null ? rawConsent.tags.split(',') : []
            };
        });
    }
}
