import { getField, updateField } from 'vuex-map-fields';
import * as employeeIdeasForm from '@/resources/employee-ideas-form';
import ioc from '@Framework/ioc';
const createFindConsent = (consents) => (consentID) => consents.find((c) => +c.consentID === +consentID);
export default {
    namespaced: true,
    state: {
        fields: [],
        formStatus: 'start',
        formData: {
            subject: '',
            name: '',
            division: '',
            description: '',
            descriptionLabel: '',
        },
        consents: [],
        consentsData: {},
        validators: {},
        validationMessagesMap: {},
        validationMessagesConsentsMap: {},
        consentErrorMessage: '',
        formMessageError: '',
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        clearData: (state) => state.formData = {
            subject: '',
            name: '',
            division: '',
            descriptionLabel: '',
            description: '',
        },
        setFormStatusSuccess: (state) => state.formStatus = 'success',
        setFormStatusFailure: (state) => state.formStatus = 'failure',
        setFormStatusStart: (state) => state.formStatus = 'start',
    },
    actions: {
        async initializeForm({ commit }) {
            const { formFields, formData, validators, validationMessagesMap, validationMessagesConsentsMap } = employeeIdeasForm;
            const consents = await ioc.get('provider.consents').fetchConsents();
            commit('updateField', { path: 'fields', value: formFields });
            commit('updateField', { path: 'formData', value: formData });
            commit('updateField', { path: 'validators', value: validators });
            commit('updateField', { path: 'validationMessagesMap', value: validationMessagesMap });
            commit('updateField', { path: 'validationMessagesConsentsMap', value: validationMessagesConsentsMap });
            commit('updateField', { path: 'consentErrorMessage', value: validationMessagesConsentsMap.required });
            commit('updateField', { path: 'consents', value: consents });
        },
        async send({ state, dispatch, commit }, { onSuccess, onFailure }) {
            const apiService = ioc.get('service.apiService');
            const responseManager = initResponseManager(onSuccess, onFailure);
            const formData = {
                ...state.formData,
                description: state.formData.description + await dispatch('getConsentText')
            };
            try {
                let response = await apiService.registerIssue(formData);
                response = await response.json();
                responseManager.call(response, response.statusCode);
            }
            catch (e) {
                responseManager.sendError(e);
            }
        },
        async getConsentText({ state }) {
            const findConsent = createFindConsent(state.consents);
            let consentsInfo = '\n --- \n Użytkownik wyraził następujące zgody: \n \n';
            for (const [consentID, value] of Object.entries(state.consentsData)) {
                if (value) {
                    const consent = findConsent(+consentID);
                    if (consent) {
                        consentsInfo += `${consent.consentID} - ${consent.ID} \n ${consent.name} \n \n`;
                    }
                }
            }
            return consentsInfo;
        }
    }
};
function initResponseManager(onSuccess, onFailure) {
    const responseManager = ioc.get('service.responseManager');
    const validResponse = /^2..$/;
    responseManager.register(validResponse, (data, code) => onSuccess({
        data,
        code
    }));
    responseManager.catch((e) => onFailure(e));
    responseManager.error((e) => onFailure(e));
    return responseManager;
}
