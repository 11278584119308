export function getFieldValidationMessage(fieldValidationResult, messagesMap) {
    const messages = [];
    for (const [validationName, validationResult] of Object.entries(fieldValidationResult)) {
        if (!validationResult) {
            messages.push(messagesMap[validationName]);
        }
    }
    return messages;
}
export function getMessagesForFields(fieldsValidationResults, messagesMap) {
    const fieldsMessages = {};
    for (const [fieldKey, validationResult] of Object.entries(fieldsValidationResults)) {
        const { details } = validationResult;
        fieldsMessages[fieldKey] = getFieldValidationMessage(details, messagesMap);
    }
    return fieldsMessages;
}
