// @ts-ignore

import InputSearch from './InputSearch.vue';
import { SuggesterIconIndex, SuggesterIconSubgroup } from './icons';

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  // eslint-disable-next-line func-names
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export default {
  name: 'DeviceNameField',
  components: {
    InputSearch,
    SuggesterIconSubgroup,
    SuggesterIconIndex,
  },
  props: {
    value: {
      default: '',
      type: String,
    },
    error: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    language: {
      default: 'pl',
      type: String,
    },
    searchToken: {
      type: String,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTimeHandle: null,
      suggestionList: [],
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value && value.target && value.target.value);
      },
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    searchValue: throttle(async function () {
      let result = await fetch(`https://rtl.rawlplug.com/api/search-products/${this.searchToken}`, {
        method: 'POST',
        body: `query=${this.computedValue}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      result = await result.json();
      const products = this.prepareProducts(result);
      this.suggestionList = this.prepareSuggestionList(products);
    }, 500),
    prepareProducts(products) {
      return products.hits.hits.map((document) => {
        // eslint-disable-next-line no-underscore-dangle
        const names = document._source.translations.name
          .filter((record) => (record.lang === this.language));
        const name = names.length ? names[0].data : '';

        // eslint-disable-next-line no-underscore-dangle
        function _filterTranslationsByLanguage(data, language) {
          return data.filter((record) => record.lang === language).map((product) => product.data);
        }

        // eslint-disable-next-line no-underscore-dangle
        function _getMarketingFeatures(translations, key, language) {
          let marketingFeatures = [];
          if (translations.marketing_features) {
            marketingFeatures = _filterTranslationsByLanguage(translations[key], language);
          }

          return marketingFeatures;
        }

        return {
          // eslint-disable-next-line no-underscore-dangle
          id: document._id,
          // eslint-disable-next-line no-underscore-dangle
          identifier: document._source.identifier,
          // eslint-disable-next-line no-underscore-dangle
          indexes: document._source.indexes,
          name,
          // eslint-disable-next-line no-underscore-dangle
          marketing_features: _getMarketingFeatures(document._source.translations, 'marketing_features', this.language),
          // eslint-disable-next-line  no-underscore-dangle
          features_and_benefits: _getMarketingFeatures(document._source.translations, 'features_and_benefits', this.language),
        };
      });
    },
    prepareSuggestionList(products) {
      return Array.from(this.productSuggestionMaker(products)).filter((item) => item.type === 'index');
    },
    productSuggestionMaker: function* productSuggestionsMaker(products) {
      // eslint-disable-next-line no-restricted-syntax
      for (const product of products) {
        yield {
          name: product.name,
          value: product,
          type: 'subgroup',
        };
        if (product.indexes && product.indexes.length) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in product.indexes) {
            yield {
              name: product.indexes[i],
              subgroup: product,
              type: 'index',
            };
          }
        }
      }
    },
    suggesterItemClick(item) {
      this.$emit('productClick', item);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
