import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
let RadioSelectField = class RadioSelectField extends Vue {
};
__decorate([
    Prop()
], RadioSelectField.prototype, "label", void 0);
__decorate([
    Prop()
], RadioSelectField.prototype, "inputValue", void 0);
__decorate([
    Prop()
], RadioSelectField.prototype, "id", void 0);
__decorate([
    Prop()
], RadioSelectField.prototype, "props", void 0);
__decorate([
    Prop({ type: Array, default: () => ([]) })
], RadioSelectField.prototype, "items", void 0);
__decorate([
    VModel()
], RadioSelectField.prototype, "model", void 0);
RadioSelectField = __decorate([
    Component({})
], RadioSelectField);
export default RadioSelectField;
