import TextInputField from '@Framework/components/form/fields/TextInputField.vue';
import PasswordInputField from '@Framework/components/form/fields/PasswordInputField.vue';
import CheckboxInputField from '@Framework/components/form/fields/CheckboxInputField.vue';
import SelectField from '@Framework/components/form/fields/SelectField.vue';
import CountrySelectField from '@Framework/components/form/fields/CountrySelectField.vue';
import RadioSelectField from '@Framework/components/form/fields/RadioSelectField.vue';
import DropzoneField from '@Framework/components/form/fields/DropzoneField.vue';
import DeviceUidField from '@Framework/components/form/DeviceUidField.vue';
import TextareaInputField from '@Framework/components/form/fields/TextareaInputField.vue';
import DescriptionLabel from '@App/components/DescriptionLabel.vue';
export const FORM_FIELDS = [
    {
        tag: ['input'],
        type: ['text'],
        component: TextInputField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['textarea'],
        type: ['text'],
        component: TextareaInputField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['input'],
        type: ['password'],
        component: PasswordInputField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['input'],
        type: ['device'],
        component: DeviceUidField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['input'],
        type: ['checkbox'],
        component: CheckboxInputField,
        options: {
            displayBaseLabel: false,
        }
    },
    {
        tag: ['select'],
        type: [''],
        component: SelectField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['select'],
        type: ['country'],
        component: CountrySelectField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['select'],
        type: ['radio'],
        component: RadioSelectField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['input'],
        type: ['file'],
        component: DropzoneField,
        options: {
            displayBaseLabel: true,
        }
    },
    {
        tag: ['descriptionLabel'],
        type: [''],
        component: DescriptionLabel,
        options: {
            displayBaseLabel: false,
        }
    }
];
