import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import ioc from '@Framework/ioc';
let BaseLayoutMixin = class BaseLayoutMixin extends Vue {
    getAppName() {
        return ioc.get('config.app').appName;
    }
    getAppLogo() {
        return ioc.get('config.app').appLogo;
    }
    getHeader() {
        const config = this.getLayoutConfig();
        return config.layout.slots.header;
    }
    getLayoutConfig() {
        return ioc.get('config.layout');
    }
    getFooterItems() {
        return ioc.get('config.footer').items;
    }
    getFooterRenderer() {
        return ioc.get('config.footer').rendererComponent;
    }
    get displayFooter() {
        return ioc.get('config.footer').displayFooter;
    }
};
BaseLayoutMixin = __decorate([
    Component({})
], BaseLayoutMixin);
export default BaseLayoutMixin;
