import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FormMessage = class FormMessage extends Vue {
};
__decorate([
    Prop({ default: () => ([]) })
], FormMessage.prototype, "messages", void 0);
FormMessage = __decorate([
    Component({})
], FormMessage);
export default FormMessage;
