import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceNameField from '../DeviceNameField/DeviceNameField.vue';
let DeviceUidField = class DeviceUidField extends Vue {
    constructor() {
        super(...arguments);
        this.filled = false;
        this.searchModel = '';
    }
    handleBlurClick() {
        if (!this.filled)
            this.searchModel = '';
    }
    handleClearClick() {
        this.filled = false;
        this.searchModel = '';
        this.$emit('input', '');
    }
    handleProductClick(product) {
        this.filled = true;
        this.searchModel = product.name;
        this.$emit('input', product.name);
    }
};
__decorate([
    Prop({ default: 'pl' })
], DeviceUidField.prototype, "language", void 0);
__decorate([
    Prop({ default: '123tokenctsportal' })
], DeviceUidField.prototype, "searchToken", void 0);
__decorate([
    Prop()
], DeviceUidField.prototype, "value", void 0);
DeviceUidField = __decorate([
    Component({
        components: { DeviceNameField }
    })
], DeviceUidField);
export default DeviceUidField;
