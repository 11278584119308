import '@Framework/installConfigs';
import installDependencies from '@Framework/installDependencies';
import installPlugins from '@Framework/installPlugins';
import installComponents from '@Framework/installComponents';
installDependencies();
function install(vue, options = {}) {
    installPlugins(vue);
    installComponents(vue);
}
function getVueConfig(app) {
    return {
        render: (h) => h(app)
    };
}
export default {
    install,
    getVueConfig,
};
