//
//
//
//

import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class BaseInputField extends Vue {

}
