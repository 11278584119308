import HelloWorld from '@App/components/HelloWorld.vue';
import iocProvider from '@Framework/components/iocProvider';
import FormBuilder from '@Framework/components/form/FormBuilder.vue';
import EmployeeIdeasForm from '@/components/EmployeeIdeasForm.vue';
import VBox from '@/components/VBox.vue';
import ResultBox from '@/components/ResultBox.vue';
import SuccessIcon from '@/assets/SuccessIcon.svg';
import ErrorIcon from '@/assets/ErrorIcon.svg';
import CheckboxInputField from '@App/components/CheckboxInputField.vue';
import BackButton from '@App/components/BackButton.vue';
export default () => ([
    ['hello-world', HelloWorld],
    ['ioc-provider', iocProvider],
    ['form-builder', FormBuilder],
    ['employee-ideas-form', EmployeeIdeasForm],
    ['v-box', VBox],
    ['result-box', ResultBox],
    ['success-icon', SuccessIcon],
    ['error-icon', ErrorIcon],
    ['checkbox-input-field', CheckboxInputField],
    ['back-button', BackButton],
]);
