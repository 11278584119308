export const requestManager = {
    post,
    put,
    get,
};
async function post(hostname, path, body, headers) {
    const headerOptions = {
        'Content-Type': 'application/json',
        ...headers,
    };
    return await fetch(hostname + path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers(headerOptions),
    });
}
function put(hostname, path, body) {
    const headerOptions = {};
    if (typeof body === 'string') {
        // @ts-ignore
        headerOptions['Content-Type'] = 'application/json';
    }
    return fetch(hostname + path, {
        method: 'PUT',
        body,
        headers: new Headers(headerOptions),
    });
}
function get(hostname, path) {
    const headerOptions = {};
    return fetch(hostname + path, {
        headers: new Headers(headerOptions),
    });
}
