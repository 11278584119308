export default [
    'Produkcja – Montaż',
    'Produkcja – Tworzywa Sztuczne',
    'Produkcja – Chemia',
    'Produkcja – MSP',
    'Produkcja – Kontroling Produkcji',
    'Dział Techniczny – Utrzymanie Ruchu',
    'Dział Techniczny – Narzędziownia',
    'Dział Techniczny – Zarządzanie Produkcją',
    'R&D – Certyfikacja/Laboratorium/Rozwój Produktów/Rozwój Elektronarzędzi',
    'R&D – Jakość',
    'Łańcuch Dostaw – Magazyn',
    'Łańcuch Dostaw – Zakupy',
    'Łańcuch Dostaw – Planowanie Logistyczne',
    'Łańcuch Dostaw – Logistyka',
    'Łańcuch Dostaw – Jakość',
    'Łańcuch Dostaw – DOK Export',
    'Biuro Zrównoważonego Rozwoju – Administracja',
    'Biuro Zrównoważonego Rozwoju - EHS',
    'Biuro Zrównoważonego Rozwoju – HR',
    'Finanse – Finanse',
    'Finanse – IT',
    'Dział Prawny',
    'Product Management',
    'Marketing – Conten&Design/Komunikacja/Akademia Rawlplug',
    'Marketing PL – Marketing/Marketing i Sprzedaż',
    'Wsparcie Sprzedaży',
    'Sprzedaż – DIY/POS/Rozwój Biznesu/Obsługa Klienta',
    'Sprzedaż PL – Oddziały Krajowe',
];
