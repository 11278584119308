import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FooterRenderer = class FooterRenderer extends Vue {
};
__decorate([
    Prop()
], FooterRenderer.prototype, "items", void 0);
FooterRenderer = __decorate([
    Component
], FooterRenderer);
export default FooterRenderer;
