import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import ioc from '@Framework/ioc';
let MenuRenderer = class MenuRenderer extends Vue {
    getMenu() {
        return ioc.get('config.menu').menus[this.menu];
    }
    get menuComponent() {
        return this.getMenu().component;
    }
    get menuItems() {
        return this.getMenu()
            .items
            .map((menuItem) => ({
            ...menuItem,
            component: menuItem.component ? menuItem.component : 'li'
        }));
    }
};
__decorate([
    Prop()
], MenuRenderer.prototype, "menu", void 0);
MenuRenderer = __decorate([
    Component
], MenuRenderer);
export default MenuRenderer;
