import { __decorate } from "tslib";
import ioc from '@Framework/ioc';
import { Component, Provide, Vue } from 'vue-property-decorator';
let iocProvider = class iocProvider extends Vue {
    constructor() {
        super(...arguments);
        this.ioc = ioc;
    }
    render() {
        return this.$slots.default;
    }
};
__decorate([
    Provide()
], iocProvider.prototype, "ioc", void 0);
iocProvider = __decorate([
    Component
], iocProvider);
export default iocProvider;
