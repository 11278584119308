import ioc from '@Framework/ioc';
import MenuRenderer from '@Framework/components/MenuRenderer.vue';
import ApplicationRoot from '@Framework/components/ApplicationRoot.vue';
const frameworkComponents = [
    ['application-root', ApplicationRoot],
    ['menu-renderer', MenuRenderer]
];
export default function installComponents(vue) {
    frameworkComponents.forEach(([id, component]) => {
        vue.component(id, component);
    });
    ioc.get('config.components').forEach(([id, component]) => {
        vue.component(id, component);
    });
}
