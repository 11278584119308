import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let CopyrightText = class CopyrightText extends Vue {
};
__decorate([
    Prop()
], CopyrightText.prototype, "companyName", void 0);
CopyrightText = __decorate([
    Component({})
], CopyrightText);
export default CopyrightText;
