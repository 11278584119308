import { __decorate } from "tslib";
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { unwrap } from '@/directives/unwrap';
let ConsentField = class ConsentField extends Vue {
};
__decorate([
    Prop()
], ConsentField.prototype, "id", void 0);
__decorate([
    Prop()
], ConsentField.prototype, "props", void 0);
__decorate([
    Prop()
], ConsentField.prototype, "items", void 0);
__decorate([
    VModel()
], ConsentField.prototype, "model", void 0);
ConsentField = __decorate([
    Component({
        directives: {
            unwrap
        }
    })
], ConsentField);
export default ConsentField;
