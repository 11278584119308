export function validateFormField(fieldRules, validators, value) {
    const details = {};
    let correct = true;
    for (const [rule, isActive] of Object.entries(fieldRules)) {
        if (isActive) {
            details[rule] = validators[rule](value);
        }
        if (!details[rule])
            correct = false;
    }
    return {
        correct,
        details,
    };
}
export function validateForm(formFields, formData, validators) {
    const fieldsResults = formFields
        .filter(field => !field.options?.hidden)
        .filter(field => {
        return Object.entries(field.validations).some(validator => {
            const [validatorName, validatorStatus] = validator;
            return validatorStatus;
        });
    })
        .map((field) => [field.key, validateFormField(field.validations, validators, formData[field.key])])
        .reduce((acc, field) => {
        acc[field[0]] = field[1];
        return acc;
    }, {});
    const everyFieldIsOK = Object.values(fieldsResults).every((field) => field.correct);
    return {
        correct: everyFieldIsOK,
        fieldsResults
    };
}
export const validationMessagesMap = {
    required: "Pole jest wymagane",
};
