import ioc from '@Framework/ioc';
import AppConfig from '@AppConfig/AppConfig';
import ComponentsConfig from '@AppConfig/ComponentsConfig';
import DependenciesConfig from '@AppConfig/DependenciesConfig';
import FooterConfig from '@AppConfig/FooterConfig';
import LayoutConfig from '@AppConfig/LayoutConfig';
import MenuConfig from '@AppConfig/MenuConfig';
import PluginsConfig from '@AppConfig/PluginsConfig';
import RouterConfig from '@AppConfig/RouterConfig';
ioc.set('config.app', function () { return AppConfig(); }, {});
ioc.set('config.components', function () { return ComponentsConfig(); }, {});
ioc.set('config.dependencies', function () { return DependenciesConfig(); }, {});
ioc.set('config.footer', function () { return FooterConfig(); }, {});
ioc.set('config.layout', function () { return LayoutConfig(); }, {});
ioc.set('config.menu', function () { return MenuConfig(); }, {});
ioc.set('config.plugins', function () { return PluginsConfig(); }, {});
ioc.set('config.router', function () { return RouterConfig(); }, {});
