import divisions from './divisions';
export default [
    {
        tag: 'input',
        type: 'text',
        key: 'subject',
        label: 'Temat lub tytuł zgłaszanego pomysłu',
        props: {
            class: 'input',
        },
        validations: {
            required: true,
        },
        options: {
            hidden: false,
            classGroup: 'col-1-1',
            classLabel: 'required',
        }
    },
    {
        tag: 'input',
        type: 'text',
        key: 'name',
        label: 'Twoje imię i nazwisko',
        props: {
            class: 'input',
            placeholder: 'np. Jan Kowalski'
        },
        validations: {
            required: true,
        },
        options: {
            hidden: false,
            classGroup: 'col-1-2',
            classLabel: 'required',
        }
    },
    {
        tag: 'select',
        type: '',
        key: 'division',
        label: 'Dział, w którym pracujesz',
        props: {
            class: 'select',
            classLabel: 'required',
        },
        validations: {
            required: true,
        },
        options: {
            items: divisions,
            classGroup: 'col-1-2',
            classLabel: 'required',
        }
    },
    {
        tag: 'descriptionLabel',
        type: '',
        key: 'descriptionLabel',
        label: '',
        props: {},
        validations: {},
        options: {
            classGroup: 'col-1-1'
        }
    },
    {
        tag: 'textarea',
        type: 'text',
        key: 'description',
        label: 'W kilku zdaniach opisz swój pomysł',
        props: {
            class: 'textarea',
            classLabel: 'required',
        },
        validations: {
            required: true,
        },
        options: {
            classGroup: 'col-1-1',
            classLabel: 'required',
        },
    },
];
