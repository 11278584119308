import { __decorate } from "tslib";
import { Component, Ref, Vue } from 'vue-property-decorator';
let Submitable = class Submitable extends Vue {
    setSubmitButtonDisabled(disabled) {
        this.submitButton.setAttribute('disabled', disabled ? 'disabled' : '');
    }
};
__decorate([
    Ref()
], Submitable.prototype, "submitButton", void 0);
Submitable = __decorate([
    Component
], Submitable);
export default Submitable;
