import BaseHeader from '@Framework/layouts/base-layout/BaseHeader.vue';
import EmployeeIdeasLayout from '@/layouts/EmployeeIdeasLayout.vue';
export default () => ({
    layout: {
        component: EmployeeIdeasLayout,
        slots: {
            header: {
                component: BaseHeader,
                props: {},
            }
        }
    },
    asideComponent: null,
    menuRenderer: null,
});
