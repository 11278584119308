import Home from '@/views/Home.vue';
import Thanks from '@/views/Thanks.vue';
import Fail from '@/views/Fail.vue';
export default () => ({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                parent: null,
                getBreadcrumbItem: () => ([]),
                crumbs: [],
                routeFor: ['CLIENT']
            }
        },
        {
            path: '/thanks',
            name: 'Thanks',
            component: Thanks,
            meta: {
                parent: null,
                getBreadcrumbItem: () => ([]),
                crumbs: [],
                routeFor: ['CLIENT']
            }
        },
        {
            path: '/fail',
            name: 'Fail',
            component: Fail,
            meta: {
                parent: null,
                getBreadcrumbItem: () => ([]),
                crumbs: [],
                routeFor: ['CLIENT']
            }
        },
    ]
});
