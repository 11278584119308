import { __decorate } from "tslib";
import { Component, Ref, Inject } from 'vue-property-decorator';
import FormBuilder from '@Framework/components/form/FormBuilder.vue';
import FormConsents from '@/components/FormConsents.vue';
import Submitable from '@/mixins/Submitable';
import { mapFields } from 'vuex-map-fields';
let EmployeeIdeasForm = class EmployeeIdeasForm extends Submitable {
    async send() {
        const validationStatus = this.form.validate();
        const validationConsentsStatus = this.formConsents.validate();
        if (validationStatus && validationConsentsStatus) {
            this.setSubmitButtonDisabled(true);
            await this.$store.dispatch('ideasRegisterModule/send', {
                onSuccess: () => this.$router.push({ name: 'Thanks' }),
                onFailure: () => this.$router.push({ name: 'Fail' })
            });
        }
    }
    async mounted() {
        await this.$store.dispatch('ideasRegisterModule/initializeForm');
        this.$store.commit('ideasRegisterModule/clearData');
    }
};
__decorate([
    Ref()
], EmployeeIdeasForm.prototype, "form", void 0);
__decorate([
    Ref()
], EmployeeIdeasForm.prototype, "formConsents", void 0);
__decorate([
    Ref()
], EmployeeIdeasForm.prototype, "submit", void 0);
__decorate([
    Inject()
], EmployeeIdeasForm.prototype, "ioc", void 0);
EmployeeIdeasForm = __decorate([
    Component({
        components: { FormBuilder, FormConsents },
        computed: {
            ...mapFields('ideasRegisterModule', [
                'formStatus',
                'formData',
                'fields',
                'consents',
                'consentsData',
                'validators',
                'validationMessagesMap',
                'consentErrorMessage',
                'formMessageError',
            ])
        }
    })
], EmployeeIdeasForm);
export default EmployeeIdeasForm;
