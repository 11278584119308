import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
let CheckboxInputField = class CheckboxInputField extends Vue {
};
__decorate([
    Prop()
], CheckboxInputField.prototype, "label", void 0);
__decorate([
    Prop()
], CheckboxInputField.prototype, "inputValue", void 0);
__decorate([
    Prop()
], CheckboxInputField.prototype, "id", void 0);
__decorate([
    Prop()
], CheckboxInputField.prototype, "props", void 0);
__decorate([
    VModel()
], CheckboxInputField.prototype, "model", void 0);
CheckboxInputField = __decorate([
    Component({})
], CheckboxInputField);
export default CheckboxInputField;
