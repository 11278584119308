import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let VBox = class VBox extends Vue {
    get style() {
        return {
            '--border-color': this.borderTopColor,
            '--title-color': this.titleColor
        };
    }
};
__decorate([
    Prop()
], VBox.prototype, "boxIcon", void 0);
__decorate([
    Prop({ default: 'transparent' })
], VBox.prototype, "borderTopColor", void 0);
__decorate([
    Prop({ default: 'black' })
], VBox.prototype, "titleColor", void 0);
__decorate([
    Prop()
], VBox.prototype, "boxTitle", void 0);
VBox = __decorate([
    Component({})
], VBox);
export default VBox;
