import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import ioc from '@Framework/ioc';
let ApplicationRoot = class ApplicationRoot extends Vue {
    getAppLayout() {
        return ioc.get('config.layout').layout.component;
    }
};
ApplicationRoot = __decorate([
    Component
], ApplicationRoot);
export default ApplicationRoot;
