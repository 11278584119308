import FooterRenderer from '@Framework/components/FooterRenderer.vue';
import FlexContainer from '@Framework/components/containers/FlexContainer.ts';
import CopyrightText from '@Framework/components/CopyrightText.vue';
import VLink from '@/components/VLink';
export default () => ({
    rendererComponent: FooterRenderer,
    displayFooter: true,
    items: [
        [
            FlexContainer,
            {
                'justifyEnd': true,
                'wrap': true,
                'alignCenter': true,
                'class': 'flex-renderer'
            },
            [
                [
                    VLink,
                    {
                        href: '',
                        target: '_blank',
                    },
                    'Polityka prywatności'
                ],
                [
                    VLink,
                    {
                        href: 'https://rodo.rawlplug.com/',
                        target: '_blank',
                    },
                    'RODO'
                ],
                [
                    CopyrightText,
                    {
                        companyName: 'Rawlplug S.A.'
                    },
                    []
                ]
            ]
        ],
    ]
});
