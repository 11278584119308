export function validateConsent(consent, checked) {
    if (consent.isRequired) {
        return checked;
    }
    return true;
}
export function validateConsents(consents, consentData) {
    const fieldsResults = consents
        .map((consent) => ({
        consentID: consent.consentID,
        correct: validateConsent(consent, consentData[consent.consentID])
    }));
    const resultObject = fieldsResults.reduce((acc, field) => {
        acc[field.consentID] = field.correct;
        return acc;
    }, {});
    const everyFieldIsOK = fieldsResults.every((field) => field.correct);
    return {
        correct: everyFieldIsOK,
        resultObject
    };
}
