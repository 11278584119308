export default function getNestedData(property, def, data) {
    if (!data)
        return def;
    const nestedPath = property.split('.');
    if (nestedPath?.length > 1) {
        let _property = data;
        for (const path of nestedPath) {
            if (typeof _property[path] != undefined) {
                _property = _property[path];
            }
            else {
                return def;
            }
        }
        return _property;
    }
    return data?.[property] ?? def;
}
