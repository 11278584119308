import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import SelectField from '@Framework/components/form/fields/SelectField.vue';
const countriesData = {
    pl: require('../fields-data/countries/pl.json'),
    cs: require('../fields-data/countries/cs.json'),
    en: require('../fields-data/countries/en.json'),
    fr: require('../fields-data/countries/fr.json'),
    it: require('../fields-data/countries/it.json'),
    ru: require('../fields-data/countries/ru.json'),
    sk: require('../fields-data/countries/sk.json'),
};
let CountrySelectField = class CountrySelectField extends Vue {
    get _options() {
        if (!this.props?.language) {
            console.error('Not specified language for CountrySelectField');
            return [];
        }
        const countries = [];
        for (const [key, value] of Object.entries(countriesData[this.props.language])) {
            countries.push({ code: key, label: value });
        }
        return countries;
    }
};
__decorate([
    Prop()
], CountrySelectField.prototype, "id", void 0);
__decorate([
    Prop()
], CountrySelectField.prototype, "props", void 0);
__decorate([
    VModel()
], CountrySelectField.prototype, "model", void 0);
CountrySelectField = __decorate([
    Component({
        components: { SelectField }
    })
], CountrySelectField);
export default CountrySelectField;
