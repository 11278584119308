import { __decorate } from "tslib";
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
let TextInputField = class TextInputField extends Vue {
};
__decorate([
    Prop()
], TextInputField.prototype, "id", void 0);
__decorate([
    Prop()
], TextInputField.prototype, "props", void 0);
__decorate([
    VModel()
], TextInputField.prototype, "model", void 0);
TextInputField = __decorate([
    Component({})
], TextInputField);
export default TextInputField;
