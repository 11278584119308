import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ResultBox = class ResultBox extends Vue {
    get classes() {
        return `rounded-border-top--${this.type === 'success' ? 'green' : 'red'}`;
    }
};
__decorate([
    Prop({ default: 'success' })
], ResultBox.prototype, "type", void 0);
__decorate([
    Prop({})
], ResultBox.prototype, "boxTitle", void 0);
ResultBox = __decorate([
    Component({})
], ResultBox);
export default ResultBox;
